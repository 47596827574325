export default {
  SignUpPage: {
    signUp: 'Sign Up',
  },
  ValidationErrors: {
    required: '{{field}} is required!',
    moreThan: 'It must be more than {{min}} characters',
    lessThan: 'It must be less than {{max}} characters',
    onlyAlphabets: 'It must contain only alphabets',
    numberRequired: 'It must contain number',
    upperLowercaseLetterRequired: 'It must contain  upper/lowercase letter',
    specialRequired: 'It must contain special',
    pleaseEnterPhoneNumber: 'Please enter phone number',
    phoneNumberDoesNotMatch: 'Phone number must be like (123) 456-7890',
    emailMustBeValid: 'Please enter a valid email',
    lastName: 'Last name',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    role: 'Role',
    mustBeTrimmed: 'The field cannot include leading and trailing spaces',
  },
  ForgotPasswordForm: {
    email: 'Email',
    enterEmail: 'Enter email',
    sendLink: 'Send link',
    linkSuccessfullySent: 'Link has been successfully sent to your email!',
  },
  ResetPasswordForm: {
    newPassword: 'New password',
    enterPassword: 'Enter password',
    confirmPassword: 'Confirm password',
    resetPassword: 'Reset password',
    passwordMustMatch: 'Passwords must match',
    passwordSuccessfullyChanged: 'Password has been successfully changed!',
  },
  SignUpForm: {
    email: 'Email',
    enterEmail: 'Enter email',
    firstName: 'First name',
    enterFirstName: 'Enter first name',
    lastName: 'Last name',
    enterLastName: 'Enter last name',
    password: 'Password',
    enterPassword: 'Enter password',
    confirmPassword: 'Confirm password',
    confirmYourPassword: 'Confirm your password',
    passwordMustMatch: 'Passwords must match',
    signUp: 'Sign up',
    pleaseConfirmEmail: 'Please confirm your email',
    alreadyHaveAnAccount: 'Already have an account?',
    firstNameRequired: 'Please enter your first name',
    lastNameRequired: 'Please enter your last name',
    confirmRequired: 'Confirm password is a required field!',
  },
  EmailConfirmationPage: {
    emailVerified: 'Email has been verified',
  },
  EmailVerified: {
    loginPage: 'Login page',
    login: 'Log in',
  },
  EmailNotVerified: {
    loginPage: 'Login page',
    resend: 'Resend code',
  },
  ProfileCardTabs: {
    contact: 'Contact',
    account: 'Account',
    notifications: 'Notifications',
  },
  ArchivingButton: {
    archive: 'Archive',
    unarchive: 'Unarchive',
    archiveConfirmation: 'Are you sure you want to archive this owner?',
    ownerArchived: 'Owner has been archived',
    ownerActivated: 'Owner has been activated',
  },
  EditLocationsModal: {
    editLocations: 'Edit locations',
    ok: 'OK',
  },
  EditLocationsForm: {
    locationNameIsRequired: 'Location name is required!',
    enterLocation: 'Please enter one location at least',
    mustBeTrimmed: 'The field cannot include leading and trailing spaces',
    lessThan: 'The length must be less than {{max}}',
    allowedSigns: `only {{signs}} signs are allowed`,
  },
  ProductRegionSelect: {
    productRegionPreference: 'Product Region Preference',
  },
  LocationsTable: {
    name: 'Name',
    type: 'Type',
    save: 'Save',
    close: 'Close',
    edit: 'Edit',
    sureToSave: 'Are you sure you want to save?',
    sureToDelete: 'Are you sure you want to delete?',
    locationDeleted: 'Location has been deleted',
    locationUpdated: 'Location has been updated',
    locationCreated: 'Location has been created',
    forInternalUsers: 'For internal users',
    forExternalUsers: 'For external users',
    forCommunityOwners: 'For community owners',
    forDaycareOwners: 'For daycare owners',
    mustBeTrimmed: 'The field cannot include leading and trailing spaces',
    lessThan: 'The length must be less than {{max}}',
    allowedSigns: `only {{signs}} signs are allowed`,
    locationNameIsRequired: 'Location name is required!',
    accountingCode: 'IDEXX accounting code',
  },
  CreateLocationsForm: {
    name: 'Name',
    type: 'Type',
    enterLocationName: 'Enter location name',
    enterRegionName: 'Enter region name',
    create: 'Create',
    locationCreated: 'Location has been created',
    accountingCode: 'IDEXX accounting code',
  },
  LocationTypeSelect: {
    selectLocationType: 'Select location type',
    forInternalUsers: 'For internal users',
    forExternalUsers: 'For external users',
    forDaycareOwners: 'For daycare owners',
    forCommunityOwners: 'For community owners',
    mustBeTrimmed: 'The field cannot include leading and trailing spaces',
  },
  InvitationRolesSelect: {
    collectionTeam: 'Collection Team',
    dogHandler: 'Dog Handler',
    inventoryTeam: 'Inventory Team',
    medicalDirector: 'Medical Director',
    owner: 'Owner',
    admin: 'Admin',
  },
};
